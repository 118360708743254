.FSLM-header
{
    margin: auto;
    padding-top: 5vh;
    padding-bottom: 5vh;
    height: auto;
    width: 60%;

	font-size: 24px;

    text-decoration: underline;

    color: #EDF2F4;
    background-color: #0C1821;
}

.FSLM-headerText
{
    padding-top: 5vh;

    color: #EDF2F4;
}

.FSLM-bodybackground
{
    background-color: #540B0E;

    height: 70vh;

    padding: 5vh;
    padding-top: 1vh;
    padding-bottom: 1vh;
}

.FSLM-backButtonContainer
{
    background-color: transparent;

    height: auto;
    width: auto;

    padding-top: 1%;
    margin-left: 2%;

    text-align: left;
}

.FSLM-bodyContainer
{
    background-color: #0C1821;

    height: 80%;
    width: 90%;

    margin: auto;
    margin-top: 2%;

    border: 1vh;
    border-radius: 1vh;
}

.FSLM-leftBox
{
    float: left;

    height: 80%;
    width: 40%;

    display: flex;
    justify-content: center;
    align-items: center;
}

.FSLM-projectImage
{
    height: auto;
    width: 90%;
}

.FSLM-rightBox
{
    float: right;

    height: 80%;
    width: 60%;
}

.FSLM-projectTitle
{
    text-align: left;

    color: #EDF2F4;

    background-color: transparent;
}

.FSLM-descriptionTitle
{
    padding-top: 2vh;

    text-align: left;

    color: #EDF2F4;
}

.FSLM-projectDescription
{
    padding-top: 1vh;

    text-align: left;
    margin-right: 10%;

    color: #EDF2F4;
}

.FSLM-linkTitle
{
    padding-top: 5vh;

    text-align: left;

    color: #EDF2F4;
}

.FSLM-linkDiv
{
    width: 100%;
    height: 10%;
}

.FSLM-link
{
    float: left;
    margin-left: 0;

    color: #EDF2F4;
}

.FSLM-or
{
    font-size: 22px;
    text-align: left;

    color: #EDF2F4;
}

/* @media screen and (max-width:1920px)
{
    .FSLM-bodyContainer
    {
        height: 80%;
    }

    .FSLM-link
    {
        font-size: 24px;
    }

    .FSLM-linkDiv
    {
        height: 4vh;
        width: 30%;
    }

    .FSLM-or
    {
        font-size: 22px;
    }
}

@media screen and (max-width:1366px)
{
    .FSLM-bodyContainer
    {
        height: 100%;
    }

    .FSLM-link
    {
        font-size: 18px;
    }

    .FSLM-linkDiv
    {
        height: 5vh;
        width: 60%;
    }

    .FSLM-or
    {
        font-size: 18px;
    }
} */