.popup
{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #0C182150;

    display: flex;
    justify-content: center;
    align-items: center;
}

.popupInner
{
    position: relative;
    padding: 32px;
    width: 100%;
    max-width: 640px;
    background-color: #EDF2F4;

    border: 1vh;
    border-radius: 1vh;
    box-shadow: 5px 5px 5px #0C1821;
}

.popupInner .closeButton
{
    position: absolute;
    top: 16px;
    right: 16px;
}

.closeButton
{

}