.video-responsive {
    overflow: hidden;

    width: 100%;
  }
  
  .video-responsive iframe {
    height: 100%;
    width: 100%;
  }
  