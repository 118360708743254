.Osiris-header
{
    margin: auto;
    padding-top: 5vh;
    padding-bottom: 5vh;
    height: auto;
    width: 60%;

	font-size: 24px;

    text-decoration: underline;

    color: #EDF2F4;
    background-color: #0C1821;
}

.Osiris-headerText
{
    padding-top: 5vh;

    color: #EDF2F4;
}

.Osiris-bodybackground
{
    background-color: #540B0E;

    height: 70vh;

    padding: 5vh;
    padding-top: 1vh;
    padding-bottom: 1vh;
}

.Osiris-backButtonContainer
{
    background-color: transparent;

    height: auto;
    width: auto;

    padding-top: 1%;
    margin-left: 2%;

    text-align: left;
}

.Osiris-bodyContainer
{
    background-color: #0C1821;

    height: 80%;
    width: 90%;

    margin: auto;
    margin-top: 2%;

    border: 1vh;
    border-radius: 1vh;
}

.Osiris-leftBox
{
    float: left;

    height: 80%;
    width: 40%;

    display: flex;
    justify-content: center;
    align-items: center;
}

.Osiris-imageBox
{
    height: 80%;
    width: 90%;
    margin: auto;
    overflow: hidden;

    display: flex;
    justify-content: center;

    padding-top: 7%;
}

.Osiris-rightBox
{
    float: right;

    height: 80%;
    width: 60%;
}

.Osiris-projectTitle
{
    text-align: left;

    color: #EDF2F4;

    background-color: transparent;
}

.Osiris-descriptionTitle
{
    padding-top: 2vh;

    text-align: left;

    color: #EDF2F4;
}

.Osiris-projectDescription
{
    padding-top: 1vh;

    text-align: left;
    margin-right: 10%;

    color: #EDF2F4;
}

.Osiris-linkTitle
{
    padding-top: 5vh;

    text-align: left;

    color: #EDF2F4;
}

.Osiris-linkDiv
{
    width: 100%;
    height: 10%;
}

.Osiris-link
{
    float: left;
    margin-left: 0;

    color: #EDF2F4;
}

.Osiris-or
{
    font-size: 22px;
    text-align: left;

    color: #EDF2F4;
}

/* @media screen and (max-width:1920px)
{
    .Osiris-bodyContainer
    {
        height: 80%;
    }

    .Osiris-link
    {
        font-size: 24px;
    }

    .Osiris-linkDiv
    {
        height: 4vh;
        width: 30%;
    }

    .Osiris-or
    {
        font-size: 22px;
    }
}

@media screen and (max-width:1366px)
{
    .Osiris-bodyContainer
    {
        height: 100%;
    }

    .Osiris-link
    {
        font-size: 18px;
    }

    .Osiris-linkDiv
    {
        height: 5vh;
        width: 60%;
    }

    .Osiris-or
    {
        font-size: 18px;
    }
} */