.BumperBalls-header
{
    margin: auto;
    padding-top: 5vh;
    padding-bottom: 5vh;
    height: auto;
    width: 60%;

	font-size: 24px;

    color: #0C1821;
    background-color: #EDF2F4;

    text-decoration: underline;
}

.BumperBalls-headerText
{
    padding-top: 5vh;
}

.BumperBalls-bodybackground
{
    background-color: #540B0E;

    height: 70vh;

    padding: 5vh;

    box-shadow: inset 0px 5px 10px -5px #0C1821;
}

.BumperBalls-bodyContainer
{
    background-color: #EDF2F4;

    height: 80%;
    width: 90%;

    margin: auto;
    justify-content: center;

    border: 1vh;
    border-radius: 1vh;

    box-shadow: 5px 5px 10px #0C1821;
}

.BumperBalls-leftBox
{
    float: left;

    height: 100%;
    width: 40%;

    border: 1vh;
    border-radius: 1vh;

    margin: auto;
}

.BumperBalls-imageBox
{
    height: 80%;
    width: 80%;
    margin: auto;
    overflow: hidden;
        
    display: flex;
    justify-content: center;

    padding-top: 7%;
}

.BumperBalls-projectImage
{
    position:relative;
    
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 100%;
    width: auto;
}

.BumperBalls-rightBox
{
    float: right;

    height: 100%;
    width: 60%;

    border: 1vh;
    border-radius: 1vh;
}

.BumperBalls-projectTitle
{
    padding-top: 5vh;

    text-align: left;
    text-decoration: underline;

    font-size: 32px;
}

.BumperBalls-descriptionTitle
{
    padding-top: 5vh;

    text-align: left;

    font-size: 24px;
}

.BumperBalls-projectDescription
{
    padding-top: 1vh;

    text-align: left;
    margin-right: 10%;

    font-size: 18px;
}

.BumperBalls-linkTitle
{
    padding-top: 5vh;

    text-align: left;

    font-size: 24px;
}

.BumperBalls-link
{
    float: left;
    margin-left: 0;

    font-size: 24px;
    text-align: left;
}

.BumperBalls-link:hover
{
    color: #540B0E;
}

.BumperBalls-linkDiv
{
    height: 4vh;
    width: 30%;

    margin: auto;
    margin-left: 0;
}

.BumperBalls-or
{
    font-size: 22px;
    text-align: left;
}