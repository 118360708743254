.footerContainer
{
    padding-top: 1%;
    width: 100%;
    height: auto;

    background-color: #540B0E;
}

.footerLogo
{
    padding-top: 5vh;
    padding-bottom: 5vh;
    height: 100px;
    width: 100px;

    background-color: #540B0E;
}

.footerHeader
{
    padding-bottom: 1vh;
}

.footerText
{
    font-size: 24px;

    color: #EDF2F4;
    background-color: #540B0E;
}

.footerButton
{
    margin-left: 2px;
    padding: 0px 3px;

    color: #EDF2F4;
    background-color: transparent;
    border-color: transparent;
    border-radius: 1vh;

    font-size: 24px;
}

.footerLink:link
{
    color: #8D99AE;
    text-decoration: none;
    background-color: transparent;
}

.footerLink:visited
{
    color: #551A8B;
}

.copyrightText
{
    padding-top: 5vh;
    padding-bottom: 3vh;
    font-size: 16px;

    color: #8D99AE;
    background-color: #540B0E;
}

