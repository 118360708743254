.portfolio-header
{
    margin: auto;
    height: auto;
    width: 60%;

	font-size: 24px;
}

.portfolio-headerText
{
    padding-top: 5vh;
    text-decoration: underline;
    color: #EDF2F4;
}

.portfolio-subHeader
{
    padding: 5vh;
}

.portfolio-resumeContainer
{
    padding-top: 5vh;
    background-color: #540B0E;
}

.portfolio-resumeContainer h1
{
    color: #EDF2F4;
    text-decoration: underline;
    padding-bottom: 1vh;
    background-color: transparent;
}

.portfolio-resumeContainer a
{
    color: #EDF2F4;
    text-decoration: underline;
    background-color: transparent;
}

.portfolio-resumeContainer a:hover
{
    color: #0C1821;
}

.portfolio-releasedProjectsHeader
{
    height: 5vh;
    margin: auto;
    padding-top: 5vh;
    text-align: center;
}

.portfolio-releasedProjectsHeader h1
{
    color: #EDF2F4;
    text-decoration: underline;
}

.portfolio-releasedProjectsBackground
{
    background-color: #540B0E;

    box-shadow: inset 0px 5px 10px -5px #0C1821;

    padding: 1vh;
}

.portfolio-releasedProjectsContainer
{
    background-color: #0C1821;

    margin: auto;
    margin-top: 3vh;
    padding: 2vh;
    width: 95%;

    border: 1vh;
    border-radius: 1vh;

    box-shadow: 5px 5px 10px #0C1821;

    display: flex;
    flex-wrap: wrap;

    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    grid-auto-rows: minmax(300px, auto);
    grid-gap: 1em;
}

.portfolio-personalProjectsBackground
{
    background-color: #540B0E;

    padding: 1vh;
}

.portfolio-personalProjectsHeader
{
    height: 5vh;
    margin: auto;
    padding-top: 10vh;
    text-align: center;
}

.portfolio-personalProjectsHeader h1
{
    color: #EDF2F4;
    text-decoration: underline;
}

.portfolio-personalProjectsContainer
{
    background-color: transparent;

    margin: auto;
    margin-top: 5vh;
    padding: 2vh;
    width: 95%;

    border: 1vh;
    border-radius: 1vh;

    box-shadow: 5px 5px 10px #0C1821;

    display: flex;
    flex-wrap: wrap;

    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    grid-auto-rows: minmax(300px, auto);
    grid-gap: 1em;
}

.portfolio-cardContainer
{
    height: 300px;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
}