*
{
    margin: 0%;
    padding: 0%;

    font-family: Arial, Helvetica, sans-serif;
    text-align: center;

    color: #EDF2F4;
    background-color: #0C1821;
}

.index-header
{
    margin: auto;
    width: 60%;
    height: auto;

	font-size: 24px;

    color: #EDF2F4;
}

.index-headerText
{
    padding-top: 5vh;

    color: #EDF2F4;
}

.index-subHeader
{
    padding: 5vh;

    color: #EDF2F4;
}

.index-subHeaderText
{
    color: #EDF2F4;
}

.index-splashImage
{
    margin-top: 5vh;

    height: 300px;
    width:auto;
}

.index-body
{
    height: auto;
    width: 100%;

    box-shadow: inset 0px 5px 10px -5px #0C1821;
}

.index-bodyHeaderBackground
{
    background-color: #540B0E;
    padding-bottom: 20px;
    height: 10%;
}

.index-bodyHeader
{
    height: 30%;
    width: 100%;

    margin: auto;
    padding-top: 20px;

    color: #EDF2F4;
    background-color: transparent;
    
}

.index-bodyDescription
{
    height: 70%;
    width: 45%;

    margin: auto;

    color: #EDF2F4;
    background-color: transparent;

    font-size: 18px;
}

.index-skillbackground
{
    background-color: #540B0E;
    height: 150vh;
}

.index-skillContainer
{
    display: flex;
    margin: auto;

    width: 85%;
    height: auto;

    border: 1vh;
    border-radius: 1vh;

    box-shadow: 5px 5px 10px #0C1821;
}

.index-skillCard
{
    margin-top: 10px;
    padding: 3%;

    width: 40%;
    height: 80%;

    border: 1vh;
    border-radius: 1vh;
}

.index-skillImage
{
    margin: auto;

    padding: 5%;
    height: 150px;
    width: 150px;
}

.index-skillHeader
{
    justify-content: center;
    vertical-align: middle;
    /* text-decoration: underline; */

    height: 10vh;
    padding-bottom: 10vh;

}

.index-skillHeader h2
{
    color: #EDF2F4;
}

.index-skillCard h3
{
    padding-bottom: 5%;

    color: #540B0E;
}

.index-skillCard p
{
    padding-bottom: 5vh;

    font-weight: lighter;
}

.index-experienceBlock
{
    height: 15vh;

    color: #EDF2F4;
}

.index-skillsBlock
{
    height: 3vh;
    color: #EDF2F4;
}

.index-skillCard li
{
    color: #EDF2F4;
    padding-bottom: 1vh;
    list-style-type: none;
    /* font-weight: lighter; */
}

.index-previousWorkContainer
{
    color: #0C1821;

    height: auto;

    border: 1px;
    box-shadow: 15px 15px 0px #0C1821;
}

.index-previousWorkContainer h1
{
    color: #EDF2F4;
    background-color: #540B0E;

    padding-bottom: 3vh;
    text-decoration: underline;
}

.index-worksContainer
{
    display: flex;
    flex-wrap: wrap;
    margin: auto;

    height: 100vh;
    width: 85%;
}

.index-workCard
{
    display: flex;
    float: left;
    margin: auto;

    height: 25%;
    width: auto;

    border-radius: 1vh;
}

.index-detailsButtonContainer
{
    height: auto;
    width: 100%;

    padding-top: 5vh;
}

.index-moreDetailsButton
{
    border-color: #540B0E;

    float: right;

    height: 60px;
    width: 275px;

    border: 5px solid #540B0E;
    border-radius: 2vh;
    overflow: hidden;

    box-shadow: 5px 5px 10px #0C1821;

    transition-duration: 0.index-4s;

    margin: auto;
}

.index-moreDetailsButton:hover
{
    background-color: #8D99AE;
}

.index-moreDetailsLink
{
    color: #EDF2F4;
    background-color: transparent;

    font-size: 18px;
    text-decoration: none;
}

@media screen and (max-width:1920px)
{
    .index-body
    {
        height: auto;
    }

    .index-skillContainer
    {
        height: 85%;
    }

    .index-skillHeader
    {
        justify-content: center;
        vertical-align: middle;

        height: 10vh;
        padding-bottom: 5vh;

        font-size: 32px;
    }

    .index-skillCard p
    {
        font-size: 20px;
    }

    .index-skillCard li
    {
        font-size: 20px;
    }
}

@media screen and (max-width:1366px)
{
    .index-body
    {
        height: auto;
    }

    .index-skillContainer
    {
        height: 87%;
    }

    .index-skillHeader
    {
        font-size: 24px;
    }

    .index-skillCard p
    {
        font-size: 14px;
    }

    .index-skillCard li
    {
        font-size: 14px;
    }
}

@media screen and (max-width:720px)
{
    .index-body
    {
        height: 345vh;
    }

    .index-skillContainer
    {
        height: 87%;
    }

    .index-skillHeader
    {
        font-size: 20px;
    }

    .index-skillCard p
    {
        font-size: 12px;
    }

    .index-skillCard li
    {
        font-size: 12px;
    }
}