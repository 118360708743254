.FPPCH-header
{
    margin: auto;
    padding-top: 5vh;
    padding-bottom: 5vh;
    height: auto;
    width: 60%;

	font-size: 24px;

    color: #0C1821;
    background-color: #EDF2F4;

    text-decoration: underline;
}

.FPPCH-headerText
{
    padding-top: 5vh;
}

.FPPCH-bodybackground
{
    background-color: #540B0E;

    height: 70vh;

    padding: 5vh;

    box-shadow: inset 0px 5px 10px -5px #0C1821;
}

.FPPCH-bodyContainer
{
    background-color: #EDF2F4;

    height: 80%;
    width: 90%;

    margin: auto;
    justify-content: center;

    border: 1vh;
    border-radius: 1vh;

    box-shadow: 5px 5px 10px #0C1821;
}

.FPPCH-leftBox
{
    float: left;

    height: 100%;
    width: 40%;

    border: 1vh;
    border-radius: 1vh;

    margin: auto;
}

.FPPCH-imageBox
{
    height: 80%;
    width: 80%;
    margin: auto;
    overflow: hidden;
        
    display: flex;
    justify-content: center;

    padding-top: 7%;
}

.FPPCH-projectImage
{
    position:relative;
    
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 100%;
    width: auto;
}

.FPPCH-rightBox
{
    float: right;

    height: 100%;
    width: 60%;

    border: 1vh;
    border-radius: 1vh;
}

.FPPCH-projectTitle
{
    padding-top: 5vh;

    text-align: left;

    font-size: 32px;
}

.FPPCH-descriptionTitle
{
    padding-top: 5vh;

    text-align: left;
    text-decoration: underline;

    font-size: 24px;
}

.FPPCH-projectDescription
{
    padding-top: 1vh;

    text-align: left;
    margin-right: 10%;

    font-size: 18px;
}

.FPPCH-linkTitle
{
    padding-top: 5vh;

    text-align: left;

    font-size: 24px;
}

.FPPCH-link
{
    float: left;
    margin-left: 0;

    font-size: 24px;
    text-align: left;
}

.FPPCH-link:hover
{
    color: #540B0E;
}

.FPPCH-linkDiv
{
    height: 4vh;
    width: 30%;

    margin: auto;
    margin-left: 0;
}

.FPPCH-or
{
    font-size: 22px;
    text-align: left;
}

@media screen and (max-width:1920px)
{
    .FPPCH-bodyContainer
    {
        height: 80%;
    }

    .FPPCH-link
    {
        font-size: 24px;
    }

    .FPPCH-linkDiv
    {
        height: 4vh;
        width: 30%;
    }

    .FPPCH-or
    {
        font-size: 22px;
    }
}

@media screen and (max-width:1366px)
{
    .FPPCH-bodyContainer
    {
        height: 100%;
    }

    .FPPCH-projectDescription
    {
        font-size: 16px;
    }

    .FPPCH-link
    {
        font-size: 18px;
    }

    .FPPCH-linkDiv
    {
        height: 5vh;
        width: 60%;
    }

    .FPPCH-or
    {
        font-size: 18px;
    }
}