*
{
    color: #EDF2F4;
}

.portfolioCard-imageBox
{
    position: relative;
    margin: auto;
    display: flex;
    justify-content: center;

    height: 100%;
    width: 100%;

    overflow: hidden;
    border-radius: 1vh;

    box-shadow: 5px 5px 10px #0C1821;
}

.portfolioCard-imageBox .portfolioCard-overlay
{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: #EDF2F4;
    background: #0C1821;
    opacity: 0;
    transition: opacity 0.3s;
}

.portfolioCard-imageBox:hover .portfolioCard-overlay
{
    opacity: .9;
}

.portfolioCard-overlay h2
{
    padding-top: 5%;
    text-decoration: underline;
    color: #EDF2F4;
}

.portfolioCard-overlay p
{
    padding: 5%;

    color: #EDF2F4;
}

.portfolioCard-Link:link
{
    color:#EDF2F4;
}

.portfolioCard-Link:hover
{
    color: #540B0E;
}

.portfolioCard-Link:visited
{
    color: #8D99AE;
}

.portfolioCard-Link:active
{
    color: #8D99AE;
}

.portfolioCard-button
{
    color: #EDF2F4;
    background-color:transparent;
    border: 2px solid #EDF2F4;
    border-radius: 1vh;
    padding: 10px 16px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    transition: background-color 0.3s;
}

.portfolioCard-button:hover
{
    background-color: #540B0E;
}

.portfolioCard-workImage
{
    position:relative;
    justify-content: center;
    
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 110%;
    width: auto;
}